import { AxiosClient } from "@/app/shared/services";
import store from "@/app/app-state";

const state = {
  auth: {
    token: localStorage.getItem("token") || undefined,
    refresh_token: localStorage.getItem("refresh_token") || undefined,
    uuid: localStorage.getItem("uuid") || undefined,
    uuid_phone: localStorage.getItem("uuid_phone") || undefined,
    phone: null,
  },

  showCookiesNotification: localStorage.getItem("cookies_notification") || true,
};

const getters = {
  AUTH_TOKEN: (state) => state.auth.token || localStorage.getItem("token"),
  REFRESH_TOKEN: (state) => state.auth.refresh_token || localStorage.getItem("refresh_token"),
  UUID: (state) => state.auth.uuid || localStorage.getItem("uuid"),
  UUID_PHONE: (state) => state.auth.uuid_phone || localStorage.getItem("uuid_phone"),
  PHONE: (state) => state.auth.phone,
};

const mutations = {
  SET_AUTH_TOKEN: (state, payload) => {
    state.auth.token = payload;
    localStorage.setItem("token", payload);
    console.log('Установили новый токен в заголовок: ', payload);
    AxiosClient.defaults.headers.common.Authorization = `Bearer ${payload}`;
  },
  SET_REFRESH_TOKEN: (state, payload) => {
    state.auth.refresh_token = payload;
    localStorage.setItem("refresh_token", payload);
  },
  SET_AUTH_UUID: (state, payload) => {
    state.auth.uuid = payload;
    localStorage.setItem("uuid", payload);
  },
  SET_AUTH_UUID_PHONE: (state, payload) => {
    state.auth.uuid_phone = payload;
    localStorage.setItem("uuid_phone", payload);
  },
  SET_PHONE: (state, payload) => {
    state.auth.phone = payload;
  },
};

const actions = {
  AUTH: (context) => {
    return new Promise((resolve, reject) => {
      console.info('Авторизация');
      AxiosClient.get("/profiles/auth")
        .then((data) => {
          context.commit("SET_AUTH_TOKEN", data.data.token);
          context.commit("SET_REFRESH_TOKEN", data.data.refresh_token);
          context.commit("SET_AUTH_UUID", data.data.uuid);

          console.info('Авторизация успешна');
          console.log('Токен: ', context.getters.AUTH_TOKEN);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка авторизации: ', error);
          reject();
        });
    });
  },
  LOGOUT: (context) => {
    return new Promise((resolve, reject) => {
      console.log(1);
      context.commit("SET_AUTH_TOKEN", undefined);
      context.commit("SET_REFRESH_TOKEN", undefined);
      context.commit("SET_AUTH_UUID", undefined);
      context.commit("SET_AUTH_UUID_PHONE", undefined);
      context.commit("SET_PHONE", undefined);
      console.log(2);
      resolve();
    });
  },
  NEW_CODE_AUTH: (context, data_request) => {
    return new Promise((resolve, reject) => {
      let payload = {
        "phone": data_request['phone']
      };

      AxiosClient.post(`/codes/${data_request["type"]}/`, payload)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка отправки кода: ', error);

          if (error.response.data.errors) {
            if (error.response.data.errors.phone) {
              reject('Неверный формат номера!');
            } else {
              reject('Ошибка формата данных');
            }
          }

          if (error.response.data.exception) {
            if (error.response.data.exception === 'UserNotFoundException') {
              reject('Такого пользователя нет');
            } else if (error.response.data.exception === 'InvalidPhoneNumberException') {
              reject('Неверный формат номера!');
            }
          }

          reject('Ошибка отправки кода');
        });
    });
  },
  VERIFICATE_CODE_AUTH: (context, data_request) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/codes/${data_request["type"]}/verificate`, data_request["payload"])
        .then((data) => {
          if (data_request["type"] === "registration") {
            context.commit("SET_AUTH_UUID_PHONE", data.data.uuid_phone);
          } else {
            context.commit("SET_AUTH_TOKEN", data.data.token);
            context.commit("SET_REFRESH_TOKEN", data.data.refresh_token);
            context.commit("SET_AUTH_UUID", data.data.uuid);
          }
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка авторизации: ', error);
          reject(error.response.data.exception);
        });
    });
  },
  REGISTRATION: (context, data_request) => {
    return new Promise((resolve, reject) => {
      data_request.profile.uuid_phone = context.getters.UUID_PHONE;
      data_request.incomes.primary_income = Number(data_request.incomes.primary_income);
      data_request.incomes.secondary_income = Number(data_request.incomes.secondary_income);

      AxiosClient.post("/profiles/registration", data_request)
        .then((data) => {
          context.commit("SET_AUTH_UUID", data.data.uuid);
          context.commit("SET_PHONE", data.data.phone);
          context.commit("SET_AUTH_TOKEN", data.data.token);
          context.commit("SET_REFRESH_TOKEN", data.data.refresh_token);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          let ex = error.response.data.exception;
          if (ex === 'UserAlreadyRegisteredException') {
            reject('Такой пользователь уже зарегистрирован');
          } else if (ex === 'CodeNotFoundException' || ex === 'CodeNotVerified') {
            reject('Вы не подтвердили телефон');
          } else if (ex === 'EmailAlreadyExistException') {
            reject('Такая почта уже зарегистрирована');
          } else if (ex === 'PhoneAlreadyExistException') {
            reject('Такой телефон уже зарегистрирован');
          }
          reject('Ошибка данных');
        });
    });
  },

  DROP_PERSONAL_DATA: (context, data_request) => {
    return new Promise((resolve, reject) => {

      AxiosClient.delete("/profiles/personal_data", { params: data_request })
        .then((data) => {
          context.commit("SET_AUTH_UUID", undefined);
          context.commit("SET_PHONE", undefined);
          context.commit("SET_AUTH_TOKEN", undefined);
          context.commit("SET_REFRESH_TOKEN", undefined);

          resolve();
        })
        .catch((error) => {
          console.error(error);
          let ex = error.response.data.exception;
          if (ex === 'UserNotFoundException') {
            reject('Такого пользователя нет');
          } else if (ex === 'InvalidPhoneNumberException') {
            reject('Неверный формат номера');
          }
          reject('Ошибка удаления');
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
