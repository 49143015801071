import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  haveNewRequest: false,
};

const getters = {
  HAVE_NEW_REQUEST(state) {
    return state.haveNewRequest;
  }
};

const mutations = {
  SET_HAVE_NEW_REQUEST(state, value) {
    state.haveNewRequest = value;
  }
};

const actions = {
};

export default {
  state,
  getters,
  mutations,
  actions,
};
