import store from "@/app/app-state";

const Middlewares = {
  authRequired(to, from, next) {
    console.log('auth token: ', store.getters.AUTH_TOKEN);

    let token = store.getters.AUTH_TOKEN;
    let tokenRefresh = store.getters.REFRESH_TOKEN;
    let uuid = store.getters.UUID;

    console.log('token: ', token);
    console.log('tokenRefresh: ', tokenRefresh);
    console.log('uuid: ', uuid);

    if (!token || !tokenRefresh || !uuid || token === 'undefined' || tokenRefresh === 'undefined' || uuid === 'undefined') {
      next("/login");
    } else {
      next(undefined);
    }

  },
};

export { Middlewares };
