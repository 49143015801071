import Vue from 'vue';
import Vuex from 'vuex';
import {
  main,
    login,
    registration,
    personalData,
    authorization,
    creditRating,
    documents
} from './client/client-states';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    main,
    login,
    registration,
    personalData,
    authorization,
    creditRating,
    documents
  },
});
